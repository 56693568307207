// the list use to add selector in footer.
var CountryList: any = [
  {
    "code": "pk",
    "name": "Pakistan",
  },
  {
    "code": "tw",
    "name": "Taiwan",
  }
]

export default CountryList
